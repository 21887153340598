.lookbooks .slick-arrow{
  width: 50px;
  height: 55px;
  line-height: 55px;
  background: #fff;
  padding: 0;
  color: #383e42;
  border-radius: 0;
  border: 1px solid #d6d6d6;
  margin: 0;
}

.lookbooks .slick-next{
  top: -51px;
  right: 0;
}

.lookbooks .slick-prev {
  top: -51px;
  left: inherit;
  right: 49px;
}

.lookbooks .slick-arrow:hover{
  background: #383e42;
}

.lookbooks .slick-arrow:hover::before{
  color: #fff;
}

.lookbooks .slick-next:before {
  top: 8px;
  right: 18px;
}

.lookbooks .slick-prev:before {
  top: 8px;
  left: 18px;
}

.single-product-info p{
  line-height: 1.8em;
  font-size: 18px;
}
