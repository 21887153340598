.privacy-list {
  list-style-type: disc;
  padding: 0;
  margin: 0;
}

.uppercase-span {
  text-transform: uppercase;
}

.bold-span {
  font-weight: bold;
}

.cookies-link {
  color: #c7c1bd;
}

.cookies-link:hover {
  color: #787878;
}

/*--------------------------------------------------------------
#13.2.	privacy-section
--------------------------------------------------------------*/
@media screen and (min-width: 1200px) {
  .privacy-section .container-fluid {
      padding: 0 85px;
  }

  .privacy-section .container-fluid > .row {
      display: flex;
      align-items: center;
  }
}

.privacy-section .privacy-area {
  text-align: right;
}

@media (max-width: 1199px) {
  .privacy-section .privacy-area {
      text-align: center;
  }
}

.privacy-section .privacy-area .info > span {
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #c7c1bd;
}

.privacy-section .privacy-area img {
  display: inline-block;
}

.privacy-section .privacy-area .info h2 {
  font-size: 48px;
  font-size: 3.2rem;
  margin: 0.3em 0 0.5em;
}

@media (max-width: 991px) {
  .privacy-section .privacy-area .info h2 {
      font-size: 35px;
      font-size: 2.3333333333rem;
  }
}

@media (max-width: 767px) {
  .privacy-section .privacy-area .info h2 {
      font-size: 30px;
      font-size: 2rem;
  }
}

.privacy-section .privacy-area .info {
  max-width: 620px;
  background-color: #fff;
  padding: 100px 65px;
  position: absolute;
  left: 0;
  top: 155px;
  box-shadow: 0px 6px 68px 0px rgba(10, 39, 50, 0.06);
}

@media (max-width: 1450px) {
  .privacy-section .privacy-area .info {
      background-color: rgba(255, 255, 255, 0.95);
      top: 100px;
      max-width: 55%;
  }
}

@media (max-width: 1199px) {
  .privacy-section .privacy-area .info {
      max-width: 100%;
      position: static;
      text-align: center;
      margin-bottom: 80px;
  }
}

.privacy-section .privacy-area .info p {
  margin-bottom: 2em;
}

@media screen and (min-width: 1200px) {
  .privacy-section .privacy-list-area {
      padding-left: 100px;
      padding-right: 100px;
  }
}

@media screen and (min-width: 1400px) {
  .privacy-section .privacy-list-area {
      padding-left: 200px;
      padding-right: 200px;
  }
}

@media (max-width: 1199px) {
  .privacy-section .privacy-list-area {
      text-align: center;
      padding: 0 80px;
      margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .privacy-section .privacy-list-area {
      padding: 0 30px;
  }
}

.privacy-section .privacy-list-area > div {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .privacy-section .privacy-list-area > div {
      margin-bottom: 45px;
  }
}

.privacy-section .privacy-list-area h3 {
  font-size: 30px;
  font-size: 2rem;
  margin: 0 0 0.5em;
  position: relative;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .privacy-section .privacy-list-area h3 {
      font-size: 25px;
      font-size: 1.6666666667rem;
  }
}

@media (max-width: 767px) {
  .privacy-section .privacy-list-area h3 {
      font-size: 22px;
      font-size: 1.4666666667rem;
  }
}

.privacy-section .privacy-list-area p {
  font-size: 16px;
  font-size: 1.0666666667rem;
  margin: 0;
  text-align: justify;
}
